/* latin */
@font-face {
  font-family: '__Crushed_809e40';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/728aa3f1d84aa791-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Crushed_Fallback_809e40';src: local("Arial");ascent-override: 107.18%;descent-override: 28.44%;line-gap-override: 3.00%;size-adjust: 82.91%
}.__className_809e40 {font-family: '__Crushed_809e40', '__Crushed_Fallback_809e40';font-weight: 400;font-style: normal
}.__variable_809e40 {--font-logo: '__Crushed_809e40', '__Crushed_Fallback_809e40'
}

